import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { RefresgtokenDialogComponent } from 'src/app/dialog/refresgtoken-dialog/refresgtoken-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {


  constructor(private router: Router, private cookieService: CookieService, private dialog: MatDialog, private AuthService: AuthService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (localStorage.getItem('authToken') == null) {
      return this.router.createUrlTree(
        ['user/'],
        { queryParams: { returnUrl: state.url } }
      );
    }
    const token = localStorage.getItem('authToken');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const isExpired = helper.isTokenExpired(token);
    const tokenList = helper.decodeToken(token);
    // console.log(isExpired);
    // console.log(tokenList.exp);
    const expires = new Date(tokenList.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    // console.log(timeout);

    const auth = next.data.expiredToken;

    if (localStorage.getItem('expcheck') === null) {
      this.AuthService.resetcredentials()
      return this.router.createUrlTree(
        ['user/'],
        { queryParams: { returnUrl: state.url } }
      );
    }

    if (timeout <= 0) {
      if (JSON.parse(localStorage.getItem('hasCheck')) < 1) {
        localStorage.setItem('expcheck', '00')
        if (localStorage.getItem('expcheck') === auth) {
          this.customDialog()
        }
      } else {
        localStorage.removeItem('expcheck')
        localStorage.setItem('expcheck', '01')
      }
    } else {
      localStorage.removeItem('expcheck')
      localStorage.setItem('expcheck', '01')
    }

    if (isExpired) {
      this.AuthService.resetcredentials()
      return this.router.createUrlTree(
        ['user/'],
        { queryParams: { returnUrl: state.url } }
      );
    } else {
      const allowedRoles = next.data.allowedRoles;
      if (allowedRoles.includes(decodedToken.roles)) {
        return true;
      } else {
        return this.router.createUrlTree(
          ['404']
        );
        return false;
      }
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }



  customDialog() {
    const dialogConfigX = new MatDialogConfig();
    dialogConfigX.backdropClass = 'backdropBackground';
    dialogConfigX.disableClose = true;
    dialogConfigX.minWidth = 'min-content'

    const dialogCustom = this.dialog.open(RefresgtokenDialogComponent, dialogConfigX)

    dialogCustom.afterClosed().subscribe((res) => {
      if (res) {
        this.AuthService.refreshToken().subscribe(res => {
          // console.log(res);
          if (res["responseCode"] == "00") {
            localStorage.removeItem('hasCheck')
            localStorage.removeItem('authToken')
            localStorage.removeItem('expcheck')
            localStorage.setItem('authToken', JSON.stringify(res["authToken"]))
            localStorage.setItem('expcheck', '01')
            localStorage.setItem('hasCheck', JSON.stringify(false))
          } else {
            localStorage.removeItem('hasCheck')
            localStorage.removeItem('expcheck')
            localStorage.setItem('expcheck', '01')
            localStorage.setItem('hasCheck', JSON.stringify(true))
          }
        })
      } else {
        localStorage.removeItem('hasCheck')
        localStorage.removeItem('expcheck')
        localStorage.setItem('expcheck', '01')
        localStorage.setItem('hasCheck', JSON.stringify(true))
      }
    })
  }




}
