import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, pipe } from 'rxjs';
import { catchError, tap, timeout, } from 'rxjs/operators';
import { LoginClientModel } from 'src/app/model/user-model/login-client';
import { RegistrationClientModel } from 'src/app/model/user-model/registration-client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _jsonURL = 'assets/json/apiserver.json';
  private apiUrl;
  private timeOut: number = 30000;
  private authToken;

  headers_object = new HttpHeaders()
    .set('Content-Type', 'application/json')

  httpOptions = {
    headers: this.headers_object
  };


  constructor(private http: HttpClient, private router: Router) {
    this.initDataToken();
    this.getUrlServer().subscribe(data => {
      this.apiUrl = data['url'];
    });
  }

  // Data Token
  initDataToken() {
    const authToken = localStorage.getItem("authToken");
    this.authToken = JSON.parse(authToken);
  }


  public getUrlServer(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  signinAdmin(data: LoginClientModel) {
    // console.log(this.httpOptions)
    const authObj = JSON.stringify(data);
    // console.log(authObj);
    return this.http.post<any>(this.apiUrl + "auth/adminLogin", authObj, this.httpOptions)
      .pipe(
        // tap(usr => {
        // }),
        timeout(this.timeOut),
        catchError(e => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      )

  }


  signinClient(data: LoginClientModel) {
    const authObj = JSON.stringify(data);
    // console.log(authObj);
    return this.http.post<any>(this.apiUrl + "auth/clientLogin", authObj, this.httpOptions)
      .pipe(
        // tap(usr => {
        //   console.log(usr);
        //   if (usr) {

        //     // this.resetcredentials();
        //     // localStorage.setItem('token', JSON.stringify(usr['token']))
        //     // localStorage.setItem('currentUser', JSON.stringify(usr))
        //     // this.isLogged = true;
        //     // this.currentUserSubject.next(usr);
        //     // this.router.navigate(["/home"]);
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }),
        timeout(this.timeOut),
        catchError(e => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      )

  }

  signup(data: RegistrationClientModel) {
    const authObj = JSON.stringify(data);
    // http://ec2-54-169-42-124.ap-southeast-1.compute.amazonaws.com/api/auth/clientRegister/
    return this.http.post<any>(this.apiUrl + "api/auth/clientRegister/", JSON.stringify(data), this.httpOptions)
      .pipe(
        tap(usr => {
          // console.log(usr);
          this.router.navigate(["/user/success"]);
        }),
        timeout(this.timeOut),
        catchError(e => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      )
  }


  logout(event) {
    //clear all localstorages and redirect to main publib page
    if (event === 1) {
      this.resetcredentials();
      this.router.navigate(['/user/']);
    } else {
      this.resetcredentials();
      this.router.navigate(['/user/']);
    }
  }

  resetcredentials() {
    //clear all localstorages
    localStorage.removeItem('authToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('subscriptionType');
    localStorage.removeItem('hasCheck');
    localStorage.removeItem('expcheck');
  }

  changePassword(dataChangePassword) {
    let authObj = JSON.stringify(dataChangePassword);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.authToken}`);

    return this.http.post(this.apiUrl + 'auth/changePassword', authObj, { headers: headers })
      .pipe(
        tap(usr => {
          // console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          return of(e)
        })
      )
  }

  refreshToken() {

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', `Bearer ${this.authToken}`);

    return this.http.post(this.apiUrl + 'auth/refreshToken', null, {
      headers: headers,
    })
      .pipe(
        tap(usr => {
          // console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          return of(e)
        })
      )
  }

  // helper methods

  private refreshTokenTimeout;

  startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const token = localStorage.getItem('authToken');
    const helper = new JwtHelperService();
    const tokenList = helper.decodeToken(token);

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(tokenList.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    console.log(this.refreshTokenTimeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }





}


