import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-refresgtoken-dialog',
  templateUrl: './refresgtoken-dialog.component.html',
  styleUrls: ['./refresgtoken-dialog.component.css']
})
export class RefresgtokenDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CustomDialogComponent>) { }

  ngOnInit() {
  }

  onNoClick(event) {
    this.dialogRef.close(event)
  }

}
