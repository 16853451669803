import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlCustomEncodeService {

  private _jsonURL = 'assets/json/apiserver.json';
  private apiUrl;

  constructor(private http: HttpClient) {
    this.getUrlServer().subscribe(data => {
      // console.log(data);
      this.apiUrl = data['url'];
    });
  }

  getUrl() {
    return this.apiUrl;
  }

  public getUrlServer(): Observable<any> {
    return this.http.get(this._jsonURL);
  }



}
