import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { mergeMap, mergeMapTo, timeout, catchError, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { NotificationService } from "./utility/notification.service";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class MessagingService {

    currentMessage = new BehaviorSubject(null);

    private _jsonURL = "assets/json/apiserver.json";

    private apiUrl;
    private timeOut: number = 30000;
    private authToken: string = '';

    constructor(private angularFireMessaging: AngularFireMessaging, private httpClient: HttpClient, private notificationService: NotificationService, private http: HttpClient) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
        this.initDataToken();

    }

    promiseFromObservable(o) {
        return new Promise(resolve => o.subscribe(resolve));
    }

    getUrlServer(): Promise<any> {
        return this.promiseFromObservable(this.http.get(this._jsonURL));
    }

    // Data Token
    initDataToken() {
        const authToken = localStorage.getItem("authToken");
        this.authToken = JSON.parse(authToken);
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                // console.log(token);
            },
            (err) => {
                // console.error('Unable to get permission to notify.', err);
            }
        );
    }

    receiveFCM() {
        return this.angularFireMessaging.messages;
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                // console.log('message', payload);
            },
            (err) => {
                // console.error('Unable to get permission to notify.', err);
            }
        );
    }

    requestToken() {
        return this.promiseFromObservable(
            this.angularFireMessaging.requestToken.pipe(
                tap(usr => {
                    // console.log(usr)
                }),
                timeout(this.timeOut),
                catchError((e) => {
                    return of(e)
                })
            )
        )
    }

    changeToken() {
        this.angularFireMessaging.requestPermission
            .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    // console.log("Permission granted! Save to the server!", token);
                },
                (error) => {
                    // console.error(error);
                }
            );
    }

    deleteToken() {
        this.angularFireMessaging.getToken
            .pipe(mergeMap((token) => this.angularFireMessaging.deleteToken(token)))
            .subscribe((token) => {
                // console.log("Token deleted!");
            });
    }

    updateFCMtoken(dataUser) {
        let postData = JSON.stringify(dataUser);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.http.post(`${this.apiUrl}users/updateFcmToken`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // ALL NOTIFICATION
    getNotificationList(dataNotification, page: any, size: any) {
        let postData = JSON.stringify(dataNotification);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            let params = new HttpParams().set('page', page).set('size', size);
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification?${params}`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    getNotificationTotal(dataNotification) {
        let postData = JSON.stringify(dataNotification);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.http.post(`${this.apiUrl}notification/getTotalNotif`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    resetTotalNotification(dataNotification) {
        let postData = JSON.stringify(dataNotification);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.http.post(`${this.apiUrl}notification/resetNotifCount`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // COMMUNITY
    addPostNotification(dataNotification) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataNotification);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/community`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // NEWS
    addNewsNotification(dataNotification) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataNotification);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/news`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // EVENT
    addEventNotification(dataReport) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataReport);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/event`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // REPORT - ASPIRASI
    addNotificationAspirasi(dataReport) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataReport);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/reportUpdate`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // REPORT - PENGADUAN
    addNotificationPengaduan(dataReport) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataReport);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/reportUpdate`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }

    // REPORT - PROPSAL
    addNotificationProposal(dataReport) {
        let key = environment.firebase.apiKey;
        let postData = JSON.stringify(dataReport);
        return this.getUrlServer().then((data) => {
            this.apiUrl = data["url"];
            return this.promiseFromObservable(
                this.httpClient.post(`${this.apiUrl}notification/reportUpdate`, `${postData}`, {
                    headers: new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `key=${key}`)
                        .set('Authorization', `Bearer ${this.authToken}`)
                }).pipe(
                    tap(usr => {
                        // console.log(usr)
                    }),
                    timeout(this.timeOut),
                    catchError((e) => {
                        return of(e);
                    })
                )
            )
        })
    }
}
