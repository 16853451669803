import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/authentication/auth.service";
import { DOCUMENT } from "@angular/common";
import * as $ from "jquery";
import { MessagingService } from "../services/messaging.service";
import { AES } from 'crypto-js';
import { ClientAccountService } from "../services/contentservices/accountservices/client-account.service";
import { Subscription } from 'rxjs';
import { NgxNotifierService } from 'ngx-notifier';
declare var $: any;

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
})

export class ContentComponent implements OnInit {
  imgUser1: any = "./assets/images/user2-128x128.jpg";
  imgUser2: any = "./assets/images/user2-160x160.jpg";
  imgUser3: any = "./assets/images/user3-128x128.jpg";
  imgUser4: any = "./assets/images/user4-128x128.jpg";
  imgUser5: any = "./assets/images/user5-128x128.jpg";
  imgUser6: any = "./assets/images/user6-128x128.jpg";
  imgUser7: any = "./assets/images/user7-128x128.jpg";
  imgUser8: any = "./assets/images/user8-128x128.jpg";

  bodyClasses = "skin-blue sidebar-mini";
  body: HTMLBodyElement = document.getElementsByTagName("body")[0];

  title = "angular-adminlte-template";
  userdata;
  private routeTitle;
  private available = true;

  userId: number = 0;
  referalCode: string = '';
  total: number = 0;
  avatar: string = '';
  subscriptionType: number = 0;
  role: string = '';
  message;
  messageIn;

  dataNotification: [];
  availableData: boolean = false;

  type;
  dataId;

  messageSubscriber: Subscription;


  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    @Inject(DOCUMENT) private _document: Document,
    private messageService: MessagingService,
    private clientService: ClientAccountService,
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private ngxNotifierService: NgxNotifierService
  ) {
    this.getUser();
    this.updateFcmToken();
    this.getUserById();
    this.getNotificationList();
    this.getTotalNotification();
    this.messageListener();




    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.getNotificationList();
        this.getTotalNotification();
      }
      else {
        this.getNotificationList();
        this.getTotalNotification();
      }
    });

    const ttl = route.snapshot.firstChild.url[0].path;
    switch (ttl) {
      case "dashboard":
        this.routeTitle = "dashboard";
        break;
      case "user-table":
        this.routeTitle = "user";
        break;
      case "news":
        this.routeTitle = "news";
        break;
      case "complaint":
        this.routeTitle = "complaint";
        break;
      case "aspiration":
        this.routeTitle = "aspiration";
        break;
      case "pengaduan":
        this.routeTitle = "pengaduan";
        break;
      case "quick-count":
        this.routeTitle = "quick-count";
        break;
      case "community":
        this.routeTitle = "community / forum";
        break;
      case "notification":
        this.routeTitle = "notification";
        break;
      case "settings":
        this.routeTitle = "settings";
        break;
    }
  }


  messageListener() {
    this.messageSubscriber = this.messageService.receiveFCM().subscribe(
      (value) => {
        console.log("value : ", value['notification'].title);
        this.getNotificationList();
        this.getTotalNotification();
        this.ngxNotifierService.createToast(value['notification'].title + "<br>" + value['notification'].body);

        setTimeout(() => {
          this.messageSubscriber.unsubscribe();

          setTimeout(() => {
            this.messageListener();
          }, 100);
        }, 500);
      }
    );
  }

  ngOnInit() {
    // TRIGGER TREEVIEW
    $("ul.sidebar-menu").tree();

    this.body.classList.add("skin-blue");
    this.body.classList.add("sidebar-mini");
  }

  // UPDATE FCM TOKEN
  updateFcmToken() {
    this.messageService.requestToken().then((token) => {
      let userId = this.userId;
      let fcmToken = token;
      let dataFcmToken = { userId, fcmToken };
      this.messageService.updateFCMtoken(dataFcmToken).then((res) => {
        // console.log(res)
      })
    })
  }

  // GET USER DATA
  getUser() {
    let userData = localStorage.getItem('currentUser');
    let parseUserData = JSON.parse(userData);
    let userId = parseUserData.user.userId;
    let referalCode = parseUserData.user.referalCode;
    this.userId = userId;
    this.referalCode = referalCode;
    this.userdata = parseUserData.user;

    if (this.userdata.role === 2) {
      this.role = "Client";
    } else if (this.userdata.role === 3) {
      this.role = "Koordinator";
    } else {
      this.role = "Administrator";
    }
  }

  // GET USER ID
  getUserById() {
    let userId = this.userId;
    this.clientService.getByUserId(userId).then((res) => {
      let results = res["responseContent"];
      let avatar = results.user.avatar;
      let subscriptionType = results.user.subscriptionType;
      this.avatar = avatar;
      this.subscriptionType = subscriptionType;
      const encrypted = AES.encrypt(JSON.stringify(subscriptionType), 'PIPEL.COM-TYPE-SUBSCRIPTION');
      localStorage.setItem("subscriptionType", encrypted);
    })
  }

  // GET NOTIFICATION LIST
  getNotificationList() {
    let userId = this.userId;
    let referalCode = this.referalCode;
    let dataNotif = { userId, referalCode };
    this.messageService.getNotificationList(dataNotif, 0, 3).then((res) => {
      let responseContent = res["responseContent"].content;
      if (responseContent.length > 0) {
        this.dataNotification = responseContent;
        this.availableData = true;
      } else {
        this.available = false;
      }
    })
  }

  // TOTAL NOTIFICATION
  getTotalNotification() {
    let userId = this.userId;
    let referalCode = this.referalCode;
    let dataNotif = { userId, referalCode };
    this.messageService.getNotificationTotal(dataNotif).then((res) => {
      let resultsContent = res["responseContent"];
      this.total = resultsContent.total;
      console.log("total notification : ", this.total);
      this._cdr.detectChanges();
      // alert("new notification");
    })
  }


  // RESET TOTAL NOTIFICATION
  resetTotalNotification() {
    let userId = this.userId;
    let referalCode = this.referalCode;
    let dataNotif = { userId, referalCode };
    this.messageService.resetTotalNotification(dataNotif).then((res) => {
      this.getTotalNotification();
    })
  }

  getDataNotification(element) {
    this.type = element.type;
    this.dataId = element.dataId;
    switch (this.type) {
      case 'news':
        this.router.navigate(['/home/', 'previewNews'], { state: { example: this.dataId } });
        break;
      case 'communityReply':
        this.router.navigate(['/home/', 'previewCommunity'], { state: { example: this.dataId } });
        break;
      case 'community':
        this.router.navigate(['/home/', 'previewCommunity'], { state: { example: this.dataId } });
        break;
      case 'event':
        this.router.navigate(['/home/', 'event']);
        break;
      case 'aspirasi':
        this.router.navigate(['/home/', 'aspiration']);
        break;
      case 'aspirasiUpdate':
        this.router.navigate(['/home/', 'aspiration']);
        break;
      case 'pengaduan':
        this.router.navigate(['/home/', 'pengaduan']);
        break;
      case 'pengaduanUpdate':
        this.router.navigate(['/home/', 'pengaduan']);
        break;
      case 'proposal':
        this.router.navigate(['/home/', 'proposal']);
        break;
      case 'proposalUpdate':
        this.router.navigate(['/home/', 'proposal']);
        break;
    }
  }

  changeTitle(event) {
    this.routeTitle = event;
  }

  logout() {
    this.auth.logout(this.userdata.role);
  }
}
