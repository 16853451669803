import { Component } from "@angular/core";
import { MessagingService } from "./services/messaging.service";
//import "../node_modules/admin-lte/build/less/AdminLTE";
//import "../node_modules/admin-lte/build/less/skins/_all-skins";
// boxed-layout-bg-image-path: "/assets/img/boxed-bg.jpg";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  messageIn;
  constructor(
    private messageService: MessagingService) {

    // this.messageService.requestPermission()
    // this.messageService.requestToken()
    // this.messageService.receiveMessage()
    // this.messageIn = this.messageService.currentMessage;

  }

  ngOnInit() {
    // this.messageService.requestPermission();
    // this.messageService.receiveMessage();

    // console.log("notif message : ", this.messageService.currentMessage);

    // this.messageIn = this.messageService.currentMessage;
  }
}
