import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusProgress'
})
export class StatusProgressPipe implements PipeTransform {

  transform(status: number): any {
    console.log('ini', status);

    switch (status) {
      case 1:
        return 'Pending'
      case 2:
        return 'Disetujui'
      case 3:
        return 'On progress'
      case 4:
        return 'Ditolak'
        break;
    }
  }
}
