import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { DeleteSubClientModel } from 'src/app/model/content-model/account-client-model/delete-client';
import { UpdateSubClientModel } from 'src/app/model/content-model/account-client-model/update-client';

@Injectable({
  providedIn: 'root'
})
export class ClientAccountService {

  private _jsonURL = "assets/json/apiserver.json";

  private apiUrl;
  private timeOut: number = 30000;
  private authToken: string = '';


  constructor(private http: HttpClient) {
    this.initDataToken();
  }

  // GET SERVER URL
  getUrlServer(): Promise<any> {
    return this.promiseFromObservable(this.http.get(this._jsonURL));
  }

  // PROMISE METHOD FUNCTION
  promiseFromObservable(o) {
    return new Promise((resolve) => o.subscribe(resolve));
  }

  // Data Token
  initDataToken() {
    const authToken = localStorage.getItem("authToken");
    this.authToken = JSON.parse(authToken);
  }

  // CLIENT
  // =================================
  // GET ALL USER BY REFERAL CODE & ROLE
  getAllDataUserByReferalCodeRole(search: string, page: string, size: string, referal: any): Promise<any> {
    return this.getUrlServer().then((data) => {
      this.apiUrl = data["url"];

      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.authToken}`);

      var parrams = new HttpParams().set("search", search).set("page", page).set("size", size)

      return this.promiseFromObservable(
        this.http.post(this.apiUrl + "users/getByReferalCode?" + parrams, referal, { headers: headers })
          .pipe(
            tap((usr) => {
              // console.log(usr);
            }),
            timeout(this.timeOut),
            catchError((e) => {
              if (e.name === "TimeoutError") {
                // this.showNotification("error", e.message)
              } else if (e.name === "HttpErrorResponse") {
                if (e.status == 401) {
                  // this.showNotification("error", e.error.message);
                } else {
                  // this.showNotification("error", e.message);
                }
              }
              return of(e);
            })
          )
      );
    });
  }


  // CLIENT
  // =================================
  // GET SUBCLIENT USER BY ID
  getSubClientByUserId(id) {

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.authToken}`);

    return this.http.post(this.apiUrl + "users/getSubClientByUserId?id=" + id, { headers: headers })
      .pipe(
        tap(usr => {
          // console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      );
  }


  //change user role 
  changeUserRole(data) {

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.authToken}`);

    return this.http.post(this.apiUrl + "users/changeRole", data, { headers: headers })
      .pipe(
        tap(usr => {
          // console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      );
  }

  getByUserId(id) {
    return this.getUrlServer().then((data) => {
      this.apiUrl = data["url"];

      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.authToken}`);

      var parrams = new HttpParams()
        .set("id", id)

      return this.promiseFromObservable(
        this.http.get(this.apiUrl + "users/getByUserId?" + parrams, { headers: headers })
          .pipe(
            tap(usr => {
              // console.log(usr)
            }),
            timeout(this.timeOut),
            catchError((e) => {
              if (e.name === "TimeoutError") {
                // this.showNotification("error", e.message)
              } else if (e.name === "HttpErrorResponse") {
                if (e.status == 401) {
                  // this.showNotification("error", e.error.message);
                } else {
                  // this.showNotification("error", e.message);
                }
              }
              return of(e);
            })
          )
      );
    })
  }

  // CLIENT
  // =================================
  // EDIT USER
  editUser(data: UpdateSubClientModel) {
    const authObj = JSON.stringify(data);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.authToken}`);

    return this.http.post<any>(this.apiUrl + "users/updateUser", authObj, { headers: headers })
      .pipe(
        tap(usr => {
          // console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      );
  }


  // CLIENT
  // =================================
  // DELETE SUB CLIENT FROM CLIENT
  deleteSubClient(data: DeleteSubClientModel) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.authToken}`
      }),
      body: {
        'userId': data.userId,
        'referalCode': data.referalCode
      },
    };

    return this.http.delete(this.apiUrl + "users/deleteSubClient", options)
      .pipe(
        tap(usr => {
          console.log(usr)
        }),
        timeout(this.timeOut),
        catchError((e) => {
          if (e.name === "TimeoutError") {
            // this.showNotification("error", e.message)
          } else if (e.name === "HttpErrorResponse") {
            if (e.status == 401) {
              // this.showNotification("error", e.error.message);
            } else {
              // this.showNotification("error", e.message);
            }
          }
          return of(e);
        })
      );
  }
}
