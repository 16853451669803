import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ContentComponent } from "./content/content.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { UrlCustomEncodeService } from "./services/utility/url-custom-encode.service";
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
} from "@angular/material";
import { StatusProgressPipe } from "./pipes/status-progress.pipe";
import { CookieService } from "ngx-cookie-service";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { MessagingService } from "./services/messaging.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { NgxNotifierModule } from "ngx-notifier";
import { AsyncPipe } from "@angular/common";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RefresgtokenDialogComponent } from './dialog/refresgtoken-dialog/refresgtoken-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ContentComponent,
    StatusProgressPipe,
    RefresgtokenDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxNotifierModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  entryComponents: [RefresgtokenDialogComponent],
  providers: [UrlCustomEncodeService, CookieService, MessagingService, AsyncPipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
