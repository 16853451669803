import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { EventEmitter } from 'events';
import { NgxNotifierService } from 'ngx-notifier';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifier: NgxNotifierService
  constructor(notifier: NgxNotifierService) {
    this.notifier = notifier;
  }

  public showNotification(message): void {
    console.log(message);
    this.notifier.createToast('Message from : ' + message.from, 'info');
  }


}
